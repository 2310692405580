<template>
  <b-card title="Para exportar lista de clientes clique no disquete quando ele aparecer" id="cardo">
    {{feedtex}}
    <div id="export-button">
      <json-excel v-if="toExport.length>0"
      :data="toExport"
      :before-generate="labelin"
      >
        <feather-icon
          icon="SaveIcon"
          size="40"
          class="ml-2 text-success pointer"
        />
      </json-excel>
    </div>
  </b-card>
</template>
<script>
import {
  BTable, BFormCheckbox, BButton, BCard, BRow, BCol, BBadge,BCardText, BLink
} from 'bootstrap-vue'
import JsonExcel from "vue-json-excel";

import gql from 'graphql-tag'
import {_} from 'vue-underscore';

export default {
  components: {
    BTable,
    BButton,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BCardText,
    BBadge,
    BLink,
    underscore: _,
    JsonExcel,
  },
  props:['searchedUsers', 'contextActions', 'addInfo', 'customFields', 'pageInfo'],
  data() {
    return {
      fields: ['id'],
      // fields: ['id', {key:'nickname', label: 'Nickname',formatter: 'cellDecoupe'},{key:'fullname', label: 'Nome',formatter: 'cellDecoupe'},{key:'created_at', label: 'Registrado em', formatter:'dateFormatter'},  {key:'invitecode', label: 'Cód Convite',formatter: 'cellDecoupe'}, { key: 'status', label: 'Status' }, { key: 'actions', label: 'Ações' }],
      status: [{
        "ACTIVATED": 'Ativado', "ACTIVED": 'Ativado', 2: 'Verificado', 3: 'Bloqueado', "PENDING_VALIDATION": 'AG. VALIDAÇÃO', 5: 'Applied',
      },
      {
        "ACTIVATED": 'light-primary', "ACTIVED": 'light-primary', 2: 'light-success', 3: 'light-danger', "PENDING_VALIDATION": 'light-warning', 5: 'light-info',
      }],
      users:null,
      queriedUsers: ['1', '9'],
      movements:null,
      userLastMovements: [],
      selectedUserLastWithDraw: [],
      selectedUserWalletId:null,
      wallets:[],
      toExport:[],
      user_ids:[],
      uDocs:null,
      feedtex:''
    }
  },
  apollo:{
    
  },
  watch:{
    user_ids: function(){
      this.makeExport()
      // _.each(this.searchedUsers, this.formatSave);
    },
    // users: function(){
    //   let obj = this.users.map(u => u.id)
    //   this.queriedUsers = obj
    // }
  },
  methods:{
    labelin(){
      this.feedtex = "Aguarde, o download iniciará automaticamente..."
    },
     makeExport(){
      this.$apollo.query({
        query: gql`query($user_ids:[ID!],$types:[String]){
         user_documents_by_group(user_ids:$user_ids,types:$types){
            user_id
            type
            value
          }
      }`,
        client: 'identityClient',
        variables: {
          user_ids: this.user_ids,
          types:["NICKNAME","FULLNAME","GENDER","BIRTHDATE","EMAIL","CLICKID","INVITECODE"]
        },
      }).then(d => {
        console.log("user_documents_by_group", d.data.user_documents_by_group);
        this.uDocs = d.data.user_documents_by_group;
         _.each(this.searchedUsers, this.formatSave);
      })
    },
    formatSave(e){
      console.log("formatSave", e);

      let toSave = {
        "id" : e.id,
        "phone": e.username,
        "status": e.status,
        "register": e.created_at,
        "invite_code": this.uDocs && this.uDocs.filter(d => d.type == "INVITECODE" && d.user_id == e.id)[0]?.value,
        "click_id": this.uDocs && this.uDocs.filter(d => d.type == "CLICKID" && d.user_id == e.id)[0]?.value,
        "birthdate": this.uDocs && this.uDocs.filter(d => d.type == "BIRTHDATE" && d.user_id == e.id)[0]?.value,
        "gender": this.uDocs && this.uDocs.filter(d => d.type == "GENDER" && d.user_id == e.id)[0]?.value,
        "nickname": this.uDocs && this.uDocs.filter(d => d.type == "NICKNAME" && d.user_id == e.id)[0]?.value,
        "fullname": this.uDocs && this.uDocs.filter(d => d.type == "FULLNAME" && d.user_id == e.id)[0]?.value,
        "email": this.uDocs && this.uDocs.filter(d => d.type == "EMAIL" && d.user_id == e.id)[0]?.value

      }
      this.toExport.push(toSave);
      console.log("toExport", this.toExport);

    },
    cellDecoupe(value, key, item){
      let obj = item.documents.filter(d => d.type == key.toUpperCase())
      return obj.length > 0 ? obj[0].value : '-'
    },
    ballancer(value, key, item){
      let obj = this.wallets.data.filter(w => w.user_id == item.id)
      return obj.length > 0 ? obj[0].balance.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) : '-'
    },
    movementRetrieve(userData){
      let wallet = this.wallets.data.filter(w => w.user_id == userData.id && w.wallet_type_id == 1)
      if(wallet.length > 0){
        this.selectedUserWalletId = wallet[0].id
      }else{
        this.selectedUserWalletId = null
      }
    },
    retrieveLastMovement(data,movementType){
      let realWallet = _.where(this.wallets.data, {user_id: data.item.id, wallet_type_id:1})
      if(this.userLastMovements.length > 0){
        let moves = _.pluck(this.userLastMovements, 'data');
        let movement = moves[0].filter(w => w.wallet_id == realWallet[0].id && w.movement_type_id == movementType).reverse()
        if(movement.length > 0)
          return this.$datter.convert((movement[0].created_at));
      }
      return 'Sem informação';
    },
    checkContains(index){
     return _.contains(this.contextActions,index); 
    },
    relationName(value, key, item){
      let typeVerifier = this.addInfo.data.filter(d => d.related_user.id == item.id)
      if(typeVerifier.length > 0){
        return typeVerifier[0].relation_type.code
      }else{
        return this.addInfo.data.filter(d => d.related_to_me.id == item.id)[0].relation_type.code
      }
    },
    removeRelation(relationId){
      this.$emit('removeRelation',relationId);
    },
    addRelation(user){
      this.$emit('addRelation',user);
    },
    relationId(item){
      let typeVerifier = this.addInfo.data.filter(d => d.related_user.id == item.id)
      if(typeVerifier.length > 0){
        return typeVerifier[0].id
      }else{
        return this.addInfo.data.filter(d => d.related_to_me.id == item.id)[0].id
      }
    },
    dateFormatter(value){
      return this.$datter.convert(value);
    },
    enc(id){
      return btoa(id);
    }
  },
  created(){
    this.user_ids = this.searchedUsers.map(function(obj) {
      return obj.id;
    });

  }
}
</script>

<style>
  .floater{
    /* position: absolute; */
    z-index: 99;
  }
  .data-table tbody{
    padding-bottom: 30px;
  }
  #export-button{
    position: absolute;
    top: 10px;
    right: 10px;
  }
  #cardo{
    position:relative
  }

</style>